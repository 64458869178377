import './App.css';
import { useEffect } from 'react';
import Home from './Components/Home/Home'
import Form from './Components/Form/Form';
import Disclosures from './Components/Disclosures/Disclosures';
import Navigation from './Components/Navigation/Navigation';
import Footer from "./Components/Footer/Footer"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Navigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, [pathname]);

  return null;
}

function App() {
  return (
    <div className="App">
      <Router basename="">
        <Navigation />
        <ScrollToTop></ScrollToTop>
        <Routes>
          <Route path="" element={<Navigate from="/home" to="home" />
          }>
          </Route>

          <Route path="home" element={<Home />}>

          </Route>

          <Route path="about" element={<Form />}>

          </Route>

          <Route path="section" element={<Disclosures />}>

          </Route>
        </Routes>

        <Footer />
      </Router>
    </div>
  );
}

export default App;
