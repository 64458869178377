import { useState, useEffect } from 'react';
import "./Navigation.css";
import { GiHamburgerMenu } from 'react-icons/gi';
import { AiOutlineClose } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';
import $ from 'jquery';
// import logo from "../../assets/logo.webp"
import logoSVG from "../../assets/logo.svg";


function Navigation(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const [toggleMenu, setToggleMenu] = useState(false);

    useEffect(() => {
        let pathSubstr = pathname.substring(1, pathname.length);

        $("*").removeClass("active");

        if (pathname.length > 1) {
            $('#' + pathSubstr).addClass("active");
            $('#' + pathSubstr + "small").addClass("active");
        }
    }, [pathname, toggleMenu]);

    const updateNavigation = () => {
        let pathSubstr = pathname.substring(1, pathname.length);

        $("*").removeClass("active");

        if (pathname.length > 1) {
            $('#' + pathSubstr).addClass("active");
        }
    }

    const handleToggleMenu = (e) => {
        setToggleMenu(!toggleMenu);
        updateNavigation();
    }

    const handleLogoClick = (e) => {
        navigate("/home");
    }

    const handleHomeClick = (e) => {
        handleToggleMenu();
        navigate("/home");
    }

    const handleFormClick = (e) => {
        handleToggleMenu();
        navigate("/about");
    }

    const handleDisclosuresClick = (e) => {
        handleToggleMenu();
        navigate("/section");
    }

    return (
        <nav className="navbar-top">

            {/* {!toggleMenu ? ( */}
            <div className='navbar-mainsize nav-interwrap'>
                <div className='logo-title-wrap' onClick={handleLogoClick}>
                    <img className="logo" src={logoSVG} alt="adinqra-logo" />
                    <h1>Ominex</h1>
                </div>

                <ul className='navlist'>
                    <li id="home" className='navlist-item' onClick={handleHomeClick}>HOME</li>
                    <li className='navlist-spacer'>*</li>
                    <li id="about" className='navlist-item' onClick={handleFormClick}>ABOUT</li>
                    <li className='navlist-spacer'>*</li>
                    <li id="section" className='navlist-item' onClick={handleDisclosuresClick}>SECTION</li>
                </ul>
            </div>
            {/* ) : null} */}


            <div className="app__navbar-smallscreen nav-interwrap">
                <div className='logo-title-wrap' onClick={handleLogoClick}>
                    <img className="logo" src={logoSVG} alt="adinqra-logo" />
                    <h1>Ominex</h1>
                </div>

                <GiHamburgerMenu className="hamburger" visibility={toggleMenu ? 'hidden' : 'visible'} onClick={handleToggleMenu} />
                {/* {toggleMenu ? ( */}
                <div className="app__navbar-smallscreen_overlay slide-bottom" style={{ display: (toggleMenu ? "" : "none") }}>
                    <AiOutlineClose fontSize={34} className="overlay__close" onClick={handleToggleMenu} />
                    <div className="app__navbar-smallscreen_links">
                        <li id="homesmall" className='small-navlist-item' onClick={handleHomeClick}><div className='small'>HOME</div></li>
                        <li id="aboutsmall" className='small-navlist-item' onClick={handleFormClick}><div className='small'>ABOUT</div></li>
                        <li id="sectionsmall" className='small-navlist-item' onClick={handleDisclosuresClick}><div className='small'>SECTION</div></li>
                    </div>
                </div>
                {/* ) : null} */}
            </div>
        </nav>
    );
}

export default Navigation;