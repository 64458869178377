import * as React from "react";
import './Disclosures.css';

function Disclosures(props) {
    const handleOnClick = (e) => {

    }

    return (
        <div className="disclosures-wrap">
            <div className="disclanding-wrap">
                <div className="disclosures-landing">
                    <div className="disc-text">
                        - Disclosures
                    </div>
                    <hr className="extendbar"></hr>
                </div>
                <div className="pdf-viewer-wrap">
                    <iframe title="disclosures-pdf" className="pdf-viewer" src="https://css4.pub/2015/icelandic/dictionary.pdf" width="400px" height="300px" />
                </div>

                {/* <div className="comingsoon">Coming soon</div> */}
                <button className="sign-btn" onClick={handleOnClick}>Sign</button>
            </div>
        </div>
    );
}

export default Disclosures;