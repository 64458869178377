import * as React from "react";
import './Home.css';
import { useNavigate } from 'react-router-dom';
import logoSVG from "../../assets/logo.svg";

function Home(props) {
    const navigate = useNavigate();

    const handleOnClick = (e) => {
        navigate("/about");
    }

    return (
        <div className="home-wrap">

            <div className="landing-wrap">
                <div className="home-landing">
                    <div className="welcome-text">Welcome to <b className="adinqra-emphasis">OMINEX</b></div>
                    <div className="adinqra-realty-wrap">
                        <b className="adinsubtext"> HEXAGODS</b>
                    </div>
                </div>

                <div className="get-started-wrap">
                    <b className="please-fill">{"> "}Get to know us below</b>

                    <button className="get-started-btn" onClick={handleOnClick}>About Us</button>
                </div>
            </div>

            <img className="home-logo" src={logoSVG} alt="adinqra-logo" />
        </div>
    );
}

export default Home;